<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="driver.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-6 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="driver.name">
      </div>
      <div class="form-group col-sm-6">
        <label for="driver_code">Driver Code:</label>
        <input class="form-control" name="driver_code" type="text" v-model="driver.driver_code">
      </div>
      <div class="form-group col-sm-4">
        <label for="license_number">License Number:</label>
        <input class="form-control" name="license_number" type="text" v-model="driver.license_number">
      </div>
      <div class="form-group col-sm-4">
        <label for="license_number_type">License Number Type:</label>
        <input class="form-control" name="license_number_type" type="text" v-model="driver.license_number_type">
      </div>
      <div class="form-group col-sm-4">
        <label for="license_number_date">License Number Date:</label>
        <input class="form-control" name="license_number_date" type="date" v-model="driver.license_number_date">
      </div>
      <div class="form-group col-sm-6">
        <label for="phone_number">Phone Number:</label>
        <input class="form-control" name="phone_number" type="text" v-model="driver.phone_number">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="gender">Gender:</label>
        <select class="form-control" name="gender" v-model.number="driver.gender">
          <option value="Male" style="color:black;">Male</option>
          <option value="Female" style="color:black;">Female</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="address">Address:</label>
        <textarea class="form-control" name="address" v-model="driver.address"></textarea>
      </div>
      <div class="form-group col-sm-6">
        <label for="ktp_number">KTP Number:</label>
        <input class="form-control" name="ktp_number" type="text" v-model="driver.ktp_number">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="driver.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
          <label for="parent_id">Ibutton:</label>
          <Select2 name="ibutton_id" v-model.number="driver.ibutton_id" :options="ibuttonsOptions" />
      </div>
      <InputImage label="Picture" v-on:selected-image="setImage"/>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/drivers">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import InputImage from '../components/InputImage.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('ibuttons', ['ibuttons']),
        ...mapState('drivers', ['onRequest']),
    },
    data(){
      return {
        driver: {
          account_id: '',
          name: null,
          driver_code: null,
          license_number: null,
          license_number_type: null,
          license_number_date: null,
          gender: 'Male',
          address: null,
          ktp_number: null,
          phone_number: null,
          status: 1,
          ibutton_id: null,
        },
        file_image: null,
        ibuttonsOptions: [],
        accountOptions: [],
      }
    },
    components:{
      InputImage,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.driver.file_image = this.file_image
        this.add(this.driver)
      },
      ...mapActions('drivers', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('ibuttons', {getIbuttons: 'get_ibutton_by_account'}),
      onChange(event){
        this.getIbuttons({account_id: parseInt(event.id)})
        this.driver.ibutton_id = null
      },
      setImage(image){
        this.file_image = image
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Driver')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      ibuttons(nextState, prevState){
        if(nextState !== prevState){
          this.ibuttonsOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.ibuttonsOptions.push({id:item.id, text:item.ibutton_no})
            })
          }
          return
        }
      }
    }
  }
</script>
